import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the EventFilter based on the Type
 */
@Pipe({
  name: 'translateFilterEvent'
})
export class TranslateFilterEventPipe implements PipeTransform {
/**
 *  Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string[], type: string): string[] {
    if (type == 'filterByPartners') {
      let returnArray = [];
      value.forEach((element) => {
        if (returnArray.length == 0) {
          returnArray.push(element['name']);
        }
      });
      return returnArray
    } else if (type == 'filterState') {
      let returnArray = [];
      value.forEach((element) => {

        switch (element) {          
          case 'CREATED':
            if (returnArray.length == 0) {
              returnArray.push("In Freigabe");
            }
            break;
          case 'DRAFT':
            if (returnArray.length == 0) {
              returnArray.push("Entwurf");
            }
            break;
          case 'PREVIEW':
            if (returnArray.length == 0) {
              returnArray.push("Vorschau");
            }
            break;
          case 'ACTIVE':
            if (returnArray.length == 0) {
              returnArray.push("Aktiv");
            }
            break;
          case 'HIDDEN':
            if (returnArray.length == 0) {
              returnArray.push("Versteckt");
            }
            break;
          case 'CANCELLED':
            if (returnArray.length == 0) {
              returnArray.push("Abgelehnt");
            }
            break;
          case 'EXPIRED':
            if (returnArray.length == 0) {
              returnArray.push("Abgelaufen");
            }
            break;
          case 'ARCHIVED':
            if (returnArray.length == 0) {
              returnArray.push("Archiviert");
            }
            break;
          case 'DELETED':
            if (returnArray.length == 0) {
              returnArray.push("Gelöscht");
            }
            break;
          default:
            returnArray.push(element);
            break;
        }
      });
      return returnArray
    } else if (type == 'filterDiscountType') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'MONETARY':
            if (returnArray.length == 0) {
              returnArray.push("Preisvorteil");
            }
            break;
          case 'SERVICE_PLUS':
            if (returnArray.length == 0) {
              returnArray.push("Exklusiv");
            }
            break;
            case 'EXTERNAL_EVENT':
              if (returnArray.length == 0) {
                returnArray.push("Partneraktion");
              }
              break;
              case 'RAFFLE':
                if (returnArray.length == 0) {
                  returnArray.push("Gewinnspiel");
                }
                break;
          default:
            returnArray.push(element);
            break;
        }
      });
      return returnArray
    }
  }
}
