import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OverlayService } from 'src/app/services/overlay.service';
import { ContactChannelResource } from 'src/app/interfaces/contactchannel';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessuserService } from 'src/app/services/businessuser.service';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the Cacelbutton
   */
  cancelText: string;
  /**
   * The Mailchannel to edit
   */
  channel: ContactChannelResource;

  userID:string;
}

@Component({
  selector: 'app-edit-mail-contact',
  templateUrl: './edit-mail-contact.component.html',
  styleUrl: './edit-mail-contact.component.scss'
})
export class EditMailContactComponent {
  editMailForm: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<EditMailContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private overlay: OverlayService,
    private fb: FormBuilder,
    private businessuserService:BusinessuserService
  ) {
    this.editMailForm = this.fb.group({
      requestConfirmation: [false]
    });

    console.log(this.data)
  }

  editChannel() {
    this.businessuserService.updateMailChannel(this.data.userID, this.data.channel,this.editMailForm.getRawValue().requestConfirmation).subscribe({
      next: value => {
        this.overlay.openSnackBar("Kontakt erfolgreich bearbeitet (E-Mail)");
        this.cancel();
      },
      error:error =>{
        if (error?.error?.message != undefined) {
          this.overlay.openSnackBar(error.error.message)
        } else {
          this.overlay.openSnackBar("Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es erneut.")
        }
      }
    })
  }

  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
